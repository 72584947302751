// Components
import Meta from '../../components/Meta/Meta';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

// CSS
import './PageWhyGoWithTrenchless.scss';

const pageTitle = `Why Trenchless Sewer Repair, Replacement, Pipe Bursting & Pipe Relining`;
const pageDescription = `Your Authority on Trenchless Sewer Repair- Find the best Contractors in your area to handle your trenchless sewer line repair or replacement.  Get estimates from pre-screened qualified contractors in your local area for Pipe Bursting & Pipe Relining Technology.`;
const pageCrumbs = [
  {
    to: '/',
    text: 'Home',
    title: 'Trenchless Sewer Repair | Trenchless Sewer Line Repairs | Sewer Pipe Repair'
  },
  {
    to: '/why-go-with-trenchless',
    text: 'Why Go With Trenchless?',
    title: pageTitle
  }
];

/**
 * PageWhyGoWithTrenchless
 * TODO - Description
 * @returns <Component />
 */
const PageWhyGoWithTrenchless = () => {
  return (
    <div
      className="PageWhyGoWithTrenchless animate__animated animate__zoomInRight"
      data-testid="PageWhyGoWithTrenchless">
      <Meta title={pageTitle} desc={pageDescription} />

      <Breadcrumbs crumbs={pageCrumbs} />

      <h1 className="text-black display-1">Why Go With Trenchless Sewer Repair?</h1>

      <p>
        Trenchless Sewer Repair can only be summed up as the most genius new technology in the sewer
        repair and replacement industry. Having to dig up your beautifully landscaped yard,
        walkways, driveway, or any structure that lay over the path of your failing sewer line can
        be expensive and heartbreaking. Thanks to new trenchless sewer technology, this nightmare is
        a thing of the past. There really is no reason why any homeowner that knows about{' '}
        <b>trenchless sewer repair</b> would ever consider replacing their sewer line any other way.
      </p>

      <h2>5 Reasons Why Trenchless Sewer Replacement is The Smart Choice for Homeowners</h2>

      <ul>
        <li>Minimal Disruption To Your Property</li>
        <li>Little or No Digging Required</li>
        <li>New Pipe Rated to Last 100+ Years</li>
        <li>Safer For The Environment</li>
        <li>Saves Time and Money</li>
      </ul>

      <h3>The 2 Most Common Types of Trenchless Sewer Repair</h3>

      <p className="lead fw-bold">Sewer Pipe Bursting</p>

      <p>
        This method <b>trenchless sewer line replacement</b> consists of having your old pipe broken
        up by a powerful cone shaped tool called a bursting head while simultaneously pulling in a
        new pipe behind it. The contractor will only need to dig two small access holes on each end
        of your sewer line. One hole to feed the new pipe in and another hole at the other end to
        pull the bursting head through pulling the brand new sewer pipe behind it.
      </p>

      <p className="lead fw-bold">Sewer Pipe Relining</p>

      <p>
        This method consists of having your existing sewer pipe relined with a durable and smooth
        epoxy resonated liner that fills and seals cracks in your old sewer line with a smooth and
        durable epoxy. In most cases, <b>sewer pipe relining</b> can performed with no digging at
        all. The pipe is accessed from a point usually in your basement called a clean-out and the
        new 'pipe within a pipe' is fed through and cured to hard and smooth durable finish.
      </p>
    </div>
  );
};

export default PageWhyGoWithTrenchless;
