// Third Party

// Components
import Meta from '../../components/Meta/Meta';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

// CSS
import './PagePipeLiningCompanies.scss';

const pageTitle = `Pipe Lining Companies | Sewer Pipe Lining Company | Sewer Line Repair`;
const pageDescription = `Trenchless Sewer Repair – Your Authority on Sewer Pipe Lining Companies. Find Sewer Pipe Lining Contractors and Suppliers in Your Area.  Expert Residential and Commercial Sewer Pipe Lining Contractors and Suppliers.`;
const pageCrumbs = [
  {
    to: '/',
    text: 'Home',
    title: 'Trenchless Sewer Repair | Trenchless Sewer Line Repairs | Sewer Pipe Repair'
  },
  {
    to: '/pipe-lining-companies',
    text: 'Pipe Lining Companies',
    title: pageTitle
  }
];

/**
 * PagePipeLiningCompanies
 * TODO - Description
 * @returns <Component />
 */
const PagePipeLiningCompanies = () => {
  return (
    <div
      className="PagePipeLiningCompanies animate__animated animate__zoomInRight"
      data-testid="PagePipeLiningCompanies">
      <Meta title={pageTitle} desc={pageDescription} />

      <Breadcrumbs crumbs={pageCrumbs} />

      <h1 className="display-1">Sewer Pipe Lining Companies</h1>

      <p>
        With the advent of new technologies in pipe lining, repairing underground pipe has gotten
        faster, much more affordable, and efficient. Whether sewer lines, water lines, or even
        electrical conduit, pipe lining can be used for a number of different applications and
        requires little or no digging.
      </p>

      <div className="embed-responsive embed-responsive-16by9 page-home-margin-top-1 page-home-min-height-315">
        <iframe
          className="embed-responsive-item"
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/nZrFO1gnTTc"
          title="How Trenchless Sewer Repair Works"
          allowFullScreen></iframe>
      </div>

      <p>
        Pipe Lining can be used to repair worn, cracked, leaky underground sewer pipes and is the
        most popular method of trenchless sewer repair because of the affordability, speed, and
        efficiency it provides. Nobody wants to call in excavation equipment to dig up yards and
        tear down structures that may lie in the path of the old sewer line as what used to be
        necessary with traditional sewer line repair or replacement. With pipe lining, these jobs
        can now be completed with minimal or sometimes no invasive digging and in many cases, be
        completed in a day or two.
      </p>

      <h2>How Does It Work?</h2>

      <p>
        After a sewer camera inspection is completed to insure the old pipe isn't too crushed or
        severely damaged (If the pipe is too damaged then sewer pipe bursting may be necessary), a
        flexible felt PVC infused liner coated with epoxy resin is blown through the entire length
        of the old sewer line using air pressure. The new liner unravels inside the old sewer line
        pressing the epoxy saturated side against the interior walls of the old sewer line. Once in
        place, a bladder is inserted inside the full length of the new liner and inflated. This
        presses the new liner tightly against the interior walls of the old sewer pipe sealing all
        cracks and voids leaving a brand new smooth, air and water tight pipe in place. Once cured
        (usually between 3 and 4 hours) this new pipe is super strong and highly resistant to future
        root intrusions, leaks, and other issues and has a life expectancy of at least 50 years.
      </p>
    </div>
  );
};

export default PagePipeLiningCompanies;
