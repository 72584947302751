import { API, Logger } from 'aws-amplify';
import { createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { listStates } from '../graphql/queries';

/**
 * Data Reducer
 * Different state updates based on the action.type
 * @param {any} state 
 * @param {{type: String, payload: any}} action 
 * @returns {any}
 */
function DataReducer(state, action) {

  logger.debug(action);

  const { type, payload } = action;
  const { field, value } = payload;

  switch (type) {

    case 'SET':

      return { ...state, ...payload };

    case 'UPDATE':

      return { ...state, [field]: value };

    default:

      throw new Error(`DataReducer - Invalid Type sent: ${type}`);
  }
}

/**
 * Data Provider Constructor
 * @returns { { width: num, state: any, dispatch: function } }
 */
function _DataProvider() {

  const [data, dispatch] = useReducer(DataReducer, INITIAL_STATE);
  const { width, states, state } = data;

  /**
   * Sets page width state based on window.innerWidth
   */
  const onResize = useCallback(() => {

    if (window.innerWidth !== width) {
      logger.debug('setting page width', window.innerWidth);
      dispatch({ type: 'UPDATE', payload: { field: 'width', value: window.innerWidth } });
    }
  }, [width]);

  /**
   * Runs only once
   * Sets page width state on window resize events
   */
  useEffect(() => {

    logger.debug('setting resize listener');
    window.addEventListener('resize', onResize);

    logger.debug('getting states');
    API.graphql({ query: listStates, authMode: 'API_KEY' }).then((res) => {
      logger.debug('found states', res.data.listStates.items.length);
      dispatch({ type: 'UPDATE', payload: { field: 'states', value: res.data.listStates.items.map(x => new State(x)) } });
    }).catch((error) => {
      // TODO error system for user
      logger.error(error);
    });

    return () => {

      logger.debug('unsetting resize listener');
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  logger.debug('constructed');

  return {
    width,
    states,
    state,
    dispatch,
  };
}

/**
 * Data Provider Renderer
 * Used above App so that Nav, Content, Layouts, and Footer can share the state
 * @param { { children: [<Component />] } } props 
 * @returns { <Provider /> }
 */
function DataProvider({ children }) {

  const dataProvider = _DataProvider();

  logger.debug('rendered');
  return <DataContext.Provider value={dataProvider}>{children}</DataContext.Provider>;
}

/**
 * Function to allow components access to the data
 * @returns { <Context /> }
 */
function UseData() {

  return useContext(DataContext);
}

/**
 * Checks if the parameter is a string with a value
 * @param {*} s 
 * @returns { bool }
 */
function IsStringAndNotEmpty(s) {

  if (typeof s !== 'string' || s.length <= 0) {

    return false;
  }

  return true;
}

/**
 * Gets the value or empty string
 * @param {*} s 
 * @returns { string }
 */
function GetString(s) {

  return IsStringAndNotEmpty(s) ? s : '';
}

/**
 * Get a link based on state or city and state
 * @param { string } state 
 * @param { string } city 
 * @returns { string } link
 */
function GetLink(state, city) {

  if (!state) {

    return 'Must send a state';
  }

  if (typeof state !== 'string') {

    return 'Must send a string for state';
  }

  if (state.length !== 2) {

    return 'Must send a valid 2 letter state';
  }

  let result = `/${state.toLowerCase()}`;

  if (!city) {

    return result;
  }

  if (typeof city !== 'string') {

    return 'Must send a string for city';
  }

  result += `/${city.replace(/\s+/g, '-').toLowerCase()}`;

  return result;
}

export class State {

  #original = null;
  abbr = '';
  name = '';
  customMessage = '';
  cities = [];
  serviceProvider = {

    name: '',
    phone: '',
    email: ''
  };
  link = '';
  hasCities = false;

  /**
   * State Class Constructor
   * - each state will have gr8 preset data :)
   * @param {*} x 
   */
  constructor(x) {

    this.#original = x;
    this.abbr = GetString(x?.abbr);
    this.name = GetString(x?.name);
    this.customMessage = GetString(x?.customMessage);
    this.cities = Array.isArray(x?.cities) ? x.cities : [];
    this.serviceProvider.name = GetString(x?.serviceProvider?.name);
    this.serviceProvider.phone = GetString(x?.serviceProvider?.phone);
    this.serviceProvider.email = GetString(x?.serviceProvider?.email);
    this.link = GetLink(this.abbr);
    this.hasCities = this.cities.length > 0;

    if (this.hasCities) {

      this.#updateCityLinks(this.abbr, this.cities);
    }
  }

  /**
   * Add a link value to each city
   * @param { string } state 
   * @param { [ { name: string, zipCodes: [string] } ] } cities 
   */
  #updateCityLinks(state, cities) {

    this.cities = cities.map((city) => {

      city.link = GetLink(state, city.name);

      return city;
    });
  }

  /**
   * In case we need to log the original data that constructed the state
   * @param { bool } return_string 
   * @returns { string || * }
   */
  viewOriginal(return_string) {

    if (!!return_string) {

      return JSON.stringify(this.#original);
    }

    return this.#original;
  }
}

const logger = new Logger('context/DataProvider.js');
const DataContext = createContext();
const INITIAL_STATE = Object.freeze({

  width: window?.innerWidth || 420,
  states: [],
  state: null,
});

export { DataProvider, UseData };
