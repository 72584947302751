// Third Party

// Components
import Meta from '../../components/Meta/Meta';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

// CSS
import './PageSewerReplacementCost.scss';

const pageTitle = `Sewer Replacement Cost | Sewer Repair Costs | Trenchless Sewer Cost`;
const pageDescription = `Trenchless Sewer Repair – Cost Of Sewer Line Repair or Replacement. Get Pricing and Estimates on Sewer Line Replacement From Your Local Sewer Contractors. Sewer Pipe Replacement Cost.`;
const pageCrumbs = [
  {
    to: '/',
    text: 'Home',
    title: 'Trenchless Sewer Repair | Trenchless Sewer Line Repairs | Sewer Pipe Repair'
  },
  {
    to: '/sewer-replacement-cost',
    text: 'Sewer Replacement Cost',
    title: pageTitle
  }
];

/**
 * PageSewerReplacementCost
 * TODO - Description
 * @returns <Component />
 */
const PageSewerReplacementCost = () => {
  return (
    <div
      className="PageSewerReplacementCost animate__animated animate__zoomInRight"
      data-testid="PageSewerReplacementCost">
      <Meta title={pageTitle} desc={pageDescription} />

      <Breadcrumbs crumbs={pageCrumbs} />

      <h1 className="display-1">Cost Of Sewer Line Replacement</h1>

      <p>
        The cost for digging up and replacing a sewer line can vary greatly depending on the
        existing conditions such as how deep the pipe is, difficulty in excavation due to
        structures, trees, or other obstructions that may need to be taken down and replaced, etc..
        For the most part, traditional dig up and replace sewer repairs can run anywhere from $100 -
        $225 per foot and of course requires digging a trench the entire length of the sewer line
        which can be several hundred feet depending on the distance your house is from the city main
        at the street.
      </p>

      <h2>Trenchless Sewer Pipe Relining & Sewer Pipe Bursting</h2>

      <p>
        Sewer Pipe Relining and Sewer Pipe Bursting are the latest technologies in making sewer pipe
        repairs or replacements as they are faster cheaper, and requires very little digging.
      </p>

      <h3>Trenchless Pipe Relining Costs</h3>

      <p>
        Trenchless Pipe Relining is exactly as the name suggests. The old sewer line is relined with
        a new resin soaked, flexible material and this requires no digging (trenchless). This is the
        most common way of repairing or replacing sewer lines today because who wants to dig up
        their yard and structures if they don't have too? This method can run anywhere between $90 -
        $175 a foot but also you must factor in the money saved by not having to replace and repair
        structures. Go here to trenchless pipe relining for more information.
      </p>

      <h4>Trenchless Pipe Bursting Costs</h4>

      <p>
        Trenchless Pipe Bursting is also a popular sewer line replacement method as this also
        requires very minimal digging. This process uses a machine that pulls new sewer pipe in
        underground while simultaneously breaking up the old pipe and pushing it into the
        surrounding soil. This method can run anywhere from $45-$105 a foot. This method is often
        used if trenchless pipe relining is not an option due to the old sewer line being to crushed
        or damaged for relining. Go here to trenchless pipe bursting for more information.
      </p>

      <p>
        For the most part, trenchless sewer line replacement is the way to go today as advances in
        technology allow for new sewer pipe to be installed more quickly, affordably and efficiently
        then the old 'dig and replace' methods.
      </p>

      <p>
        If you would like more information on trenchless sewer repair just find your local
        trenchless sewer contractors using our directory map and call them directly. Be sure to get
        written estimates detailing the work to be performed and ask about warranties on workmanship
        and installations.
      </p>
    </div>
  );
};

export default PageSewerReplacementCost;
