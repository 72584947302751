// Third party
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

// Images
import workers from '../../imgs/trenchless-sewer-repair-workers.jpg';

// CSS
import './GetListed.scss';

// TODO - move to redux?
const shadowClass = 'shadow rounded';

const GetListed = ({ startHidden }) => {
  const [hide, setHide] = useState(!!startHidden);

  const onClickHeader = () => {
    setHide((prevState) => !prevState);
  };

  return (
    <Card className={`GetListed text-center ${shadowClass}`} data-testid="GetListed">
      <Card.Header className="bg-primary text-white fs-2" onClick={onClickHeader}>
        Are You a Contractor?
      </Card.Header>

      <GetListedBody hide={hide} />
    </Card>
  );
};

const GetListedBody = ({ hide }) => {
  const history = useHistory();

  if (hide) return null;

  return (
    <Card.Body>
      <Card.Img variant="top" src={workers} alt="Trenchless Sewer Repair Contractors" />

      <Card.Title className="text-tsr-red">Becoming a Service Provider is Easy!</Card.Title>

      <Card.Text>
        Click the button below and send a request to get listed on this website.
      </Card.Text>

      <div className="d-grid gap-2">
        <Button
          variant="tsr-red text-white"
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
            history.push('/get-listed');
          }}>
          Get Listed Now!
        </Button>
      </div>
    </Card.Body>
  );
};

export default GetListed;
