// Third Party

// Components
import Meta from '../../components/Meta/Meta';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

// CSS
import './PageTrenchlessPipeLining.scss';

const pageTitle = `Trenchless Pipe Bursting | Trenchless Sewer Pipe Bursting | Pipe Bursting Contractors`;
const pageDescription = `Trenchless Sewer Repair – Your Source for Trenchless Pipe Bursting & Sewer Repair and Replacement Technology. Find Your Local Qualified Trenchless Pipe Bursting Contractors and Pipe Bursting Experts. Trenchless Pipe Bursting.`;
const pageCrumbs = [
  {
    to: '/',
    text: 'Home',
    title: 'Trenchless Sewer Repair | Trenchless Sewer Line Repairs | Sewer Pipe Repair'
  },
  {
    to: '/learn-about-services',
    text: 'Learn About Services',
    title: `Learn about Trenchless Sewer Repair Services | Trenchless Sewer Repair Information`
  },
  {
    to: '/learn-about-services/trenchless-pipe-lining',
    text: 'Trenchless Pipe Lining',
    title: pageTitle
  }
];

const PageTrenchlessPipeLining = () => {
  return (
    <div
      className="PageTrenchlessPipeLining animate__animated animate__zoomInRight"
      data-testid="PageTrenchlessPipeLining">
      <Meta title={pageTitle} desc={pageDescription} />

      <Breadcrumbs crumbs={pageCrumbs} />

      <h1 className="display-1">Trenchless Pipe Lining</h1>

      <p>
        With the progression of technology in the trenchless sewer pipe industry, inspecting,
        repairing or replacing sewer pipes has never been more cost effective or as efficient as it
        is today. Trenchless pipe lining technology is great news for homeowners, businesses, and
        general contractors as this technology requires no digging. Trenchless pipe lining is in
        most cases installed via sewer cleanout access point meaning no digging is required at all.
      </p>

      <h2 className="text-tsr-red">Watch How Trenchless Sewer Repair Works</h2>

      <div className="embed-responsive embed-responsive-16by9 page-home-margin-top-1 page-home-min-height-315">
        <iframe
          className="embed-responsive-item"
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/9-WSHuxqRec"
          title="How Trenchless Sewer Repair Works"
          allowFullScreen></iframe>
      </div>

      <p className="lead mt-3 fw-bold">Cured In Place Pipe (CIPP)</p>

      <p>
        A very flexible felt PVC material coated with epoxy resin is blown into the old pipe via air
        pressure inverting inside the pipe as it goes so the resin soaked side is pressed against
        the interior of the old pipe for adhesion. Once the pipe lining is in place, a bladder is
        fed through the new liner and expanded with air pressure tightly sealing the new pipe lining
        to the walls of the old pipe. The bladder is removed and the pipe is like brand new inside
        with a rock hard, smooth finish for excellent flow and resistance to future root intrusion,
        and water leaks. Trenchless pipe lining is by far the best way to replace or repair old
        sewer lines and can save the homeowner or business owner a fortune.
      </p>
    </div>
  );
};

export default PageTrenchlessPipeLining;
