import Meta from '../../components/Meta/Meta';

import './PageNotFound.scss';

const pageTitle = `404`;
const pageDescription = `This is not the page you are looking for`;

const PageNotFound = () => (
  <div className="PageNotFound animate__animated animate__zoomInRight" data-testid="PageNotFound">
    <Meta title={pageTitle} desc={pageDescription} />

    <h1 className="text-decoration-underline">404</h1>
  </div>
);

export default PageNotFound;
