/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getState = /* GraphQL */ `
  query GetState($id: ID!) {
    getState(id: $id) {
      abbr
      name
      customMessage
      cities {
        name
        zipCodes
        __typename
      }
      serviceProvider {
        name
        phone
        email
        __typename
      }
      createdAt
      updatedAt
      id
      __typename
    }
  }
`;
export const listStates = /* GraphQL */ `
  query ListStates(
    $filter: ModelStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        abbr
        name
        customMessage
        createdAt
        updatedAt
        id
        __typename
      }
      nextToken
      __typename
    }
  }
`;
