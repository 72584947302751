// Third Party

// Components
import Meta from '../../components/Meta/Meta';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

// CSS
import './PagePrivacyPolicy.scss';

const pageTitle = `Privacy Policy - Trenchless - Repairing America's Aging Sewer Infrastructure`;
const pageDescription = `Privacy Policy - Trenchless Sewer Line Replacement – Trenchless means No More Digging to Repair or Replace Municipal Sewer Lines. This Means Less Environmental Destruction To Access and Repair America's Aging Sewer Infrastructure.`;
const pageCrumbs = [
  {
    to: '/',
    text: 'Home',
    title: 'Trenchless Sewer Repair | Trenchless Sewer Line Repairs | Sewer Pipe Repair'
  },
  {
    to: '/privacy-policy',
    text: 'Privacy Policy',
    title: pageTitle
  }
];

const PagePrivacyPolicy = () => {
  return (
    <div
      className="PagePrivacyPolicy animate__animated animate__zoomInRight"
      data-testid="PagePrivacyPolicy">
      <Meta title={pageTitle} desc={pageDescription} />

      <Breadcrumbs crumbs={pageCrumbs} />

      <h1 className="display-1">Privacy Policy</h1>

      <h2>Website Usage - Information Collection</h2>

      <p>
        When you visit Natural Media websites, we automatically obtain information from you about
        the name of your Internet service provider, the browser and type of computer you are using,
        the website that referred you to us, the pages you request and the date and time of those
        requests. We use this information on an aggregate basis to generate statistics and measure
        site activity to improve the usefulness of the site to our visitors. For example, we use
        this information to track which pages are most popular among visitors as a whole. We may
        track the pages you or any individual visitor may look at during a visit to our site and we
        may collect other information without your knowledge. We may also collect or store
        personally identifiable information, such as your name, mailing address, email address or
        phone number if you provide that information to us when you contact us via telephone, text
        or any other means that provides such information.
      </p>

      <h2>Natural Media’s sharing of personally identifiable information</h2>

      <p>
        Natural Media may share personal information with third parties on a completely confidential
        basis to enable a third party to perform certain tasks, such as maintain a membership
        database or send email alerts and/or newsletters, on our behalf. These third party providers
        are committed to protecting the privacy of our members and subscribers, and will not utilize
        your information for any other purpose but to provide the services which we contract for.
        The Natural Media websites will not post your personal contact information, including email
        address, street address and phone numbers, without your consent.
      </p>

      <h2>Cookies</h2>

      <p>
        A cookie is a piece of data stored on the user's hard drive containing information about the
        user. The Natural Media websites uses cookies for measuring aggregate web statistics,
        including number of monthly visitors, number of repeat visitors, most popular Web pages and
        other information. Natural Media will also use cookies to facilitate your online visit by
        maintaining data that you provide for online activism activities so that you will not need
        to resubmit certain information. Natural Media does not use cookies to track what specific
        pages an individual site visitor views.
      </p>

      <h2>Opting out of email lists</h2>

      <p>
        People who subscribe to email lists via the website will receive periodic updates from
        Natural Media by email. You may opt out of receiving future information via email by using
        the unsubscribe procedure specified on the email message.
      </p>

      <h2>Outside links</h2>

      <p>
        All of our sites contain hypertext links to other sites. Natural Media is not responsible
        for the privacy practises or the content of such websites.
      </p>

      <h2>Contact us</h2>

      {/* TODO create contact us page/form */}
      <p>
        If you experience technical problems with the operation of this website,or any Natural Media
        website contact us by emailing:{' '}
        <a
          title="Contact Us | Trenchless Sewer Repair"
          href="mailto:webmaster@naturalmediaprofessionals.com?subject=Contact Us | Trenchless Sewer Repair"
          style={{ fontSize: 14 }}>
          webmaster@naturalmediaprofessionals.com
        </a>
      </p>
    </div>
  );
};

export default PagePrivacyPolicy;
