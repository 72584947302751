// Third party
// TODO For React 18 when out
// import { StrictMode } from 'react';
import { render } from 'react-dom';
import Amplify, { Logger } from 'aws-amplify';
import awsExports from './aws-exports';

// Context
import { DataProvider } from './context/DataProvider';

// Components
import App from './App';

// Css
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'animate.css';
import './index.css';

// Debug
import reportWebVitals from './reportWebVitals';

Amplify.configure({

  ...awsExports,
  Analytics: {

    disabled: true,
  },
});

if (process.env.NODE_ENV !== 'production') {

  Amplify.Logger.LOG_LEVEL = 'DEBUG';
}

const root = document.getElementById('root');
const logger = new Logger('index.js');

// TODO For React 18 when out
// render(
//   <StrictMode>
//     <Router>
//       <App />
//     </Router>
//   </StrictMode>,
//   root
// );

render(
  <DataProvider>
    <App />
  </DataProvider>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV !== 'production') {

  logger.debug('enabling web vitals');
  reportWebVitals(console.log);
}

logger.debug('rendered');
