// Third Party
import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

// Components
import Meta from '../../components/Meta/Meta';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

// Context
import { UseData } from '../../context/DataProvider';

// CSS
import './PageState.scss';

/**
 * PageState
 * TODO - Description
 * @returns <Component />
 */
const PageState = () => {
  const [page, setPage] = useState({
    state: undefined,
    title: '',
    desc: '',
    crumbs: []
  });
  const { pathname } = useLocation();
  const { states } = UseData();

  useEffect(() => {
    let path = String(
      pathname
        .split('/')
        .filter((x) => x !== '')
        .shift()
    ).toUpperCase();
    let state = states.find((x) => x.abbr === path);
    let pageTitle = `${state?.name} Trenchless Sewer Repair | Trenchless Sewer Repair Information`;
    let page = {
      state,
      title: pageTitle,
      desc: `${state?.name} Trenchless Sewer Repair – Your Authority on Residential & Commercial Trenchless Sewer Repair Technology, Information and Contractors In Your Area. Trenchless Sewer Repair`,
      crumbs: [
        {
          to: '/',
          text: 'Home',
          title: 'Trenchless Sewer Repair | Trenchless Sewer Line Repairs | Sewer Pipe Repair'
        },
        {
          to: `/${state?.abbr.toLowerCase()}`,
          text: state?.abbr,
          title: pageTitle
        }
      ]
    };

    setPage(page);
  }, [pathname, states]);

  if (!page.state) {
    return <Spinner animation="grow" className="PageState" data-testid="PageState" />;
  }

  return (
    <div className="PageState animate__animated animate__zoomInRight" data-testid="PageState">
      <Meta title={page.title} desc={page.desc} />

      <Breadcrumbs crumbs={page.crumbs} />

      <h1 className="display-1">{page.state.name} Trenchless Sewer Repair</h1>

      <p className="lead">{page.state.serviceProvider.name}</p>

      <p>
        Serving the entire state of <b>{page.state.name}</b>! Contact below or click the red{' '}
        <b className="text-tsr-red">Get a Quote Now button</b> above (or to the left).
      </p>

      <h3>Contact Info</h3>

      <p>
        <a
          title={`${page.state.serviceProvider.name} Phone`}
          target="_blank"
          rel="noreferrer"
          href={`tel:${page.state.serviceProvider.phone}`}>
          <i className="bi bi-telephone float-end text-black" /> {page.state.serviceProvider.phone}
        </a>
      </p>

      <p>
        <a
          title={`${page.state.serviceProvider.name} Email`}
          target="_blank"
          rel="noreferrer"
          href={`mailto:${page.state.serviceProvider.email}?subject=Quote Request ${page.state.abbr}`}>
          <i className="bi bi-envelope float-end text-black" /> {page.state.serviceProvider.email}
        </a>
      </p>

      <Cities state={page.state} />
    </div>
  );
};

const Cities = ({ state }) => {
  const history = useHistory();

  const onClick = (e, link) => {
    e.preventDefault();

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    history.push(link);
  };

  if (!state || !state.cities || state.cities.length === 0) {

    return null;
  }

  return (
    <Row xs={1} md={2} className="g-4">

      {state.cities.map((city, index) => {

        const s = state.abbr.toLowerCase();
        const c = city.name.toLowerCase();
        const link = `/${s}/${c}`;

        return (

          <Col key={index}>

            <Card>

              <Card.Body>

                <Card.Title>

                  <a href={link} title={`${city.name}, ${state.abbr}`} className="link-secondary" style={{ textDecoration: 'none' }} onClick={(e) => {

                    onClick(e, link);
                  }}>{city.name}, {state.abbr}</a>
                </Card.Title>

                <Card.Text>Primary Zip Codes covered.</Card.Text>

                <Card.Text>

                  {city.zipCodes.map((zipCode, i) => {

                    return (

                      <a key={i} href={link} title={`${zipCode} - ${city.name}, ${state.abbr}`} style={{ color: 'grey' }} onClick={(e) => {

                        onClick(e, link);
                      }}>{zipCode}{i === city.zipCodes.length - 1 ? '' : ', '}</a>
                    );
                  })}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

export default PageState;
