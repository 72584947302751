// Third party
import { Link, useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

// Components
import ExternalLink from '../ExternalLink/ExternalLink';

// CSS
import './FooterSite.scss';

const iconStyle = {
  margin: 8,
  fontSize: '2rem'
};

const FooterSite = ({ pageWidth }) => {
  return (
    <Container
      fluid={pageWidth > 1024 ? false : true}
      className="FooterSite"
      data-testid="FooterSite">
      <RowSwitch pageWidth={pageWidth} />

      <Row>
        <Col>
          <ExternalLink
            anchorClass="text-tsr-facebook"
            anchorTitle="Trenchless Sewer Repair | Facebook"
            anchorHREF="https://www.facebook.com/pages/Sewer-Repair-USA/543337359082892"
            iconClass="bi-facebook"
            iconStyle={iconStyle}
          />

          <ExternalLink
            anchorClass="text-tsr-twitter"
            anchorTitle="Trenchless Sewer Repair | Twitter"
            anchorHREF="https://twitter.com/SewerRepairUSA"
            iconClass="bi-twitter"
            iconStyle={iconStyle}
          />

          <ExternalLink
            anchorClass="text-tsr-youtube"
            anchorTitle="Trenchless Sewer Repair | YouTube"
            anchorHREF="https://www.youtube.com/channel/UCvOz24r7xLtby672q0KBrog"
            iconClass="bi-youtube"
            iconStyle={iconStyle}
          />
        </Col>
      </Row>
    </Container>
  );
};

const RowSwitch = ({ pageWidth }) => {
  const history = useHistory();
  const year = new Date().getFullYear();

  if (pageWidth > 1383) {
    return (
      <Row>
        <Col>
          <p>Copyright Trenchless Sewer Repair &copy; {year}</p>

          <p>
            <Button
              as={Link}
              to="/privacy-policy"
              href="/privacy-policy"
              variant="link"
              className="link-light"
              onClick={(e) => {
                e.preventDefault();
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                });
                history.push('/privacy-policy');
              }}>
              Privacy Policy
            </Button>
          </p>
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col>
        <p>Copyright Trenchless Sewer Repair &copy; {year}</p>
      </Col>

      <Col>
        <Button
          as={Link}
          to="/privacy-policy"
          href="/privacy-policy"
          variant="link"
          className="link-light"
          onClick={(e) => {
            e.preventDefault();
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
            history.push('/privacy-policy');
          }}>
          Privacy Policy
        </Button>
      </Col>
    </Row>
  );
};

export default FooterSite;
