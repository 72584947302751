// Third Party

// Components
import Meta from '../../components/Meta/Meta';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

// CSS
import './PageHome.scss';

const pageTitle = `Trenchless Sewer Repair | Trenchless Sewer Line Repairs | Sewer Pipe Repair`;
const pageDescription = `You can have any length or diameter of underground damage sewer lines replaced or repaired without digging a big trench across your property, and without the need to disturb structures like garages, porches, yards, and driveways.`;

const PageHome = () => {
  return (
    <div className="PageHome animate__animated animate__zoomInRight" data-testid="PageHome">
      <Meta title={pageTitle} desc={pageDescription} />

      <Breadcrumbs />

      <h1 className="text-black display-1">You Can Now Fix Your Sewer Without Digging</h1>

      <p>
        Advances in technology in the sewer repair industry means faster, easier, and more
        economical sewer repair and replacement for homeowners and business owners alike.
      </p>

      <p>
        Trenchless Sewer Repair means just as the name implies. You can have any length or diameter
        of underground damage sewer lines replaced or repaired{' '}
        <b>without digging a big trench across your property</b>, and without the need to disturb
        structures like garages, porches, yards, and driveways.
      </p>

      <p>
        Why would you ever even consider allowing a contractor to dig up your yard now that you know
        you don't have to?
      </p>

      <h2 className="text-tsr-red">Watch How Trenchless Sewer Repair Works</h2>

      <div className="embed-responsive embed-responsive-16by9 page-home-margin-top-1 page-home-min-height-315">
        <iframe
          className="embed-responsive-item"
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/9-WSHuxqRec"
          title="How Trenchless Sewer Repair Works"
          allowFullScreen></iframe>
      </div>
    </div>
  );
};

export default PageHome;
