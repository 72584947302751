// Third Party
import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

// Components
import Meta from '../../components/Meta/Meta';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

// Context
import { UseData } from '../../context/DataProvider';

// CSS
import './PageCity.scss';

const getCityDisplay = (city) => {
  let parts = city
    .split('-')
    .map((cityLowerCase) => cityLowerCase.charAt(0).toUpperCase() + cityLowerCase.slice(1));

  return parts.join(' ');
};

/**
 * PageCity
 * TODO - Description
 * @returns <Component />
 */
const PageCity = () => {
  const [page, setPage] = useState({
    state: undefined,
    city: undefined,
    zipCodes: [],
    title: '',
    desc: '',
    crumbs: []
  });
  const { pathname } = useLocation();
  const history = useHistory();
  const { states } = UseData();

  useEffect(() => {
    let path = pathname.split('/').filter((x) => x !== '');
    let pCity = String(path.pop());
    let pCityDisplay = getCityDisplay(pCity);
    let pState = String(path.shift());
    let state = states.find((x) => x.abbr === pState.toUpperCase());
    let pageTitle = `${pCityDisplay}, ${state?.abbr} Trenchless Sewer Repair - No Dig Cured In Place Pipe Contractor`;
    let x = state.cities.find(x => x.name === pCityDisplay);
    let y = Array.isArray(x?.zipCodes) ? x.zipCodes : [];
    let page = {
      state,
      city: pCityDisplay,
      title: pageTitle,
      desc: `Trenchless Sewer Repair - FREE ESTIMATES We Provide No Dig Sewer Repair Solutions to the ${pCityDisplay}, ${state?.abbr} area. If You Are Having Problems With Your Sewer or Drain Pipe in the ${pCityDisplay}, ${state?.abbr} Area We Offer CIPP Cured In Place Pipe Lining To Fix Your ${pCityDisplay}, ${state?.abbr} Sewer Pipe Without Digging`,
      crumbs: [
        {
          to: '/',
          text: 'Home',
          title: 'Trenchless Sewer Repair | Trenchless Sewer Line Repairs | Sewer Pipe Repair'
        },
        {
          to: `/${state?.abbr.toLowerCase()}`,
          text: state?.abbr,
          title: `${state?.name} Trenchless Sewer Repair | Trenchless Sewer Repair Information`
        },
        {
          to: `/${state?.abbr.toLowerCase()}/${pCity}`,
          text: pCityDisplay,
          title: pageTitle
        }
      ],
      zipCodes: y
    };

    setPage(page);
  }, [pathname, states]);

  if (!page.state) {
    return <Spinner animation="grow" className="PageCity" data-testid="PageCity" />;
  }

  return (
    <div className="PageCity animate__animated animate__zoomInRight" data-testid="PageCity">
      <Meta title={page.title} desc={page.desc} />

      <Breadcrumbs crumbs={page.crumbs} />

      <h1 className="display-1">
        {page.city}, {page.state.abbr} Trenchless Sewer Repair
      </h1>

      <Card className="mb-3 shadow">
        <Card.Header className="bg-black text-white">{page.state.serviceProvider.name}</Card.Header>

        <Card.Body>
          <Card.Title>
            Proudly Serving {page.city}, {page.state.abbr}
          </Card.Title>

          <Zips page={page} />

          <Card.Text className="lead">For free estimates call now!</Card.Text>
        </Card.Body>

        <Card.Footer>
          <div className="d-grid gap-2">
            <Button variant="primary" size="lg">
              <a
                title={`${page.state.serviceProvider.name} Phone`}
                target="_blank"
                rel="noreferrer"
                href={`tel:${page.state.serviceProvider.phone}`}>
                <i className="bi bi-telephone" /> {page.state.serviceProvider.phone}
              </a>
            </Button>
          </div>
        </Card.Footer>
      </Card>

      <p>
        {page.state.serviceProvider.name} provides no dig sewer trenchless sewer repair solutions to
        the {page.city}, {page.state.abbr} area. If you are having problems with roots,
        cracks, leaks or breaks in your {page.city}, {page.state.abbr} sewer line then you
        should learn about cured in place pipe or CIPP as it is commonly referred to. It is called
        trenchless because there is no digging required.
      </p>

      <p className="lead fw-bold">Sewer Lining is Fast, Affordable, and Permanent.</p>

      <ul>
        <li>No Digging</li>
        <li>Installs in One Day</li>
        <li>Roots Can Never Get in Again</li>
        <li>Fixes Your Sewer Permanently</li>
        <li>Seals All Joints, Cracks, & Breaks</li>
      </ul>

      <div className="embed-responsive embed-responsive-16by9 page-home-margin-top-1 page-home-min-height-315">
        <iframe
          className="embed-responsive-item"
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/9-WSHuxqRec"
          title={`How Trenchless Sewer Repair Works in ${page.city}, ${page.state.abbr}`}
          allowFullScreen></iframe>
      </div>

      <h2>
        Trenchless Sewer Repair in {page.city}, {page.state.abbr} - FAQs
      </h2>

      <p className="lead fw-bold">What is Trenchless Sewer Repair?</p>

      <p>
        This is a sewer pipe rehabilitation method offered by {page.state.serviceProvider.name} to
        fix any type, length, and diameter of sewer or drain pipe 2 Inches or bigger in the{' '}
        {page.city}, {page.state.abbr} area and it only requires minimal and sometimes no
        excavation at all. This is the latest technology in sewer pipe rehabilitation that is fast
        and relatively inexpense compared conventional methods.
      </p>

      <p className="lead fw-bold">What Types of Sewer Pipes Does It Work On?</p>

      <p>
        Trenchless Sewer Repair {page.city}, {page.state.abbr} will work on any type of
        existing sewer pipes found in the city of {page.city}, {page.state.abbr} including
        concrete, cast iron, clay, orangeburgh, PVC, and even old tar paper.{' '}
        {page.state.serviceProvider.name} can install a brand new sewer pipe for you in the city of
        {page.city}, {page.state.abbr} in almost all cases less than a day.
      </p>

      <p>
        Works perfectly on 3 inch, 4 inch, 6 inch, 8 inch even all the way of to 36 inch diameter
        sewer pipes.
      </p>

      <p className="lead fw-bold">Does Trenchless Sewer Repair Seal Out Roots?</p>

      <p>
        Absolutely. Because the new trenchless sewer liner {page.state.serviceProvider.name}{' '}
        installs in {page.city}, {page.state.abbr} homes and businesses is seamless, there
        are no joints to attract microscopic roots that causes almost all sewer problems in the
        first place. Microscopic roots from trees and bushes in {page.city},{' '}
        {page.state.abbr} homes and businesses work their way in and then thrive off the
        water and nutrients coming through your sewer pipe and grow rapidly inside causing major
        backups. With <b>Trenchless Sewer Repair</b> in {page.city}, {page.state.abbr} your
        new CIPP sewer pipe will have no seams.
      </p>

      <p className="lead fw-bold">How Long Will The New Sewer Liner Last?</p>

      <p>
        The trenchless sewer liners {page.state.serviceProvider.name} installs in the city of
        {page.city} are rated to last for 50 years and are 100% backed up by with a 50 Year Warranty
        against failure. So essentially CIPP trenchless sewer repair installed by{' '}
        {page.state.serviceProvider.name} repairs your sewer or pipe permanently.
      </p>

      <p className="lead fw-bold">Is It More Expensive Than Digging?</p>

      <p>
        Absolutely not, In almost all cases trenchless sewer line replacement in {page.city}{' '}
        installed by {page.state.serviceProvider.name} will save you a lot of money because there is
        no extra costs of backfilling a trench and re-landscaping your yard, repairing driveways or
        porches that may lie in the path of your {page.city}, {page.state.abbr} sewer pipe.{' '}
      </p>

      <p className="lead fw-bold">How Do I Get an Estimate For Trenchless Sewer Repair?</p>

      <p>
        {page.state.serviceProvider.name} will come to your {page.city}, {page.state.abbr}{' '}
        home or business and inspect your sewer line with a video camera and give you a free
        estimate right there on the spot to replace your {page.city}, {page.state.abbr}{' '}
        sewer using the trenchless method.. Just pick up the phone and call us at{' '}
        <a
          title={`${page.state.serviceProvider.name} Phone`}
          target="_blank"
          rel="noreferrer"
          href={`tel:${page.state.serviceProvider.phone}`}>
          <i className="bi bi-telephone" /> {page.state.serviceProvider.phone}
        </a>{' '}
        or fill out the free estimate request form on the webpage for a guaranteed response from us
        here at {page.state.serviceProvider.name} in 24 hours or less.
      </p>

      <p className="lead fw-bold">So What Are You Waiting For?</p>

      <p>
        If you are having a sewer or drain problem of any kind in the {page.city} area contact us
        today via phone call or web form submission. Our expert technicians are standing by 24/7 to
        give you all the information and provide you with a free estimate for Trenchless Sewer
        Repair in {page.city} that will fix your sewer or drain pipe permanently without digging up
        your property.
      </p>

      <p className="lead fw-bold">"Pipe Bursting" is Another Method of No Dig Solutions</p>

      <p>
        This method involves a minor amount of excavation where a small access pit will need to be
        dug at each end of the sewer or drain pipe. Pipe Bursting involves spearing and crushing the
        old sewer pipe out of the why while pulling in a new pipe simultaneously. Learn more about
        pipe bursting{' '}
        <a
          href="/learn-about-services/trenchless-pipe-bursting"
          title={`Learn about Sewer Pipe Bursting in ${page.city}, ${page.state.abbr}`}
          onClick={(e) => {
            e.preventDefault();

            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });

            history.push('/learn-about-services/trenchless-pipe-bursting');
          }}>
          here
        </a>
        .
      </p>

      <div className="embed-responsive embed-responsive-16by9 page-home-margin-top-1 page-home-min-height-315">
        <iframe
          className="embed-responsive-item"
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/FODtVBxjDxY"
          title={`How Sewer Pipe Bursting Works in ${page.city}, ${page.state.abbr}`}
          allowFullScreen></iframe>
      </div>
    </div>
  );
};

const Zips = ({ page }) => {

  if (!Array.isArray((page.zipCodes)) || page.zipCodes.length === 0) {

    return null;
  }

  return (

    <>

      <Card.Text>Serving the following zip codes and more!</Card.Text>

      <Card.Text>
        {page.zipCodes.map((zipCode, i) => {

          return (

            <span key={i} title={`${zipCode} - ${page.city}, ${page.state.abbr}`} style={{ color: 'grey' }} >{zipCode}{i === page.zipCodes.length - 1 ? '' : ', '}</span>
          );
        })}
      </Card.Text>
    </>
  );
};

export default PageCity;
