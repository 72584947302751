// Third Party
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

// Components
import Meta from '../../components/Meta/Meta';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

// Context
import { UseData } from '../../context/DataProvider';

// CSS
import './PageFindProviderByState.scss';

const pageTitle = `Find a Trenchless Sewer Repair Service Provider in your State!`;
const pageDescription = `Trenchless Sewer Repair | Service Provider search by City, state or zip code`;
const pageCrumbs = [
  {
    to: '/',
    text: 'Home',
    title: 'Trenchless Sewer Repair | Trenchless Sewer Line Repairs | Sewer Pipe Repair'
  },
  {
    to: '/find-provider-by-state',
    text: 'Find a Service Provider',
    title: pageTitle
  }
];

/**
 * PageFindProviderByState
 * TODO - Description
 * @returns <Component />
 */
const PageFindProviderByState = () => {
  const history = useHistory();
  const { states } = UseData();
  const statesList = states.map((x) => x.abbr).sort();

  return (
    <div
      className="PageFindProviderByState animate__animated animate__zoomInRight"
      data-testid="PageFindProviderByState">
      <Meta title={pageTitle} desc={pageDescription} />

      <Breadcrumbs crumbs={pageCrumbs} />

      <h1 className="display-1">Find a Trenchless Sewer Repair Service Provider</h1>

      <Form.Group className="mb-3" controlId="formState">
        <Form.Label>
          Select your State <span className="text-tsr-red">*</span>
        </Form.Label>

        <Form.Select
          size="lg"
          required
          name="state"
          onChange={(e) => {
            e.persist();
            const { value } = e.currentTarget;

            if (value) history.push(value.toLowerCase());
          }}>
          {statesList.map((state, index) => {
            return <option key={index}>{state}</option>;
          })}
        </Form.Select>
      </Form.Group>

      <p>Or scroll to find your state</p>

      <Row xs={1} md={2} className="g-4">
        {states.sort((a, b) => (a.abbr < b.abbr) ? -1 : (a.abbr > b.abbr) ? 1 : 0).map((state, index) => {
          return (
            <Col key={index}>
              <Card>
                <Card.Body>
                  <Card.Title>
                    {state.name} ({state.abbr})
                  </Card.Title>

                  <Card.Text>
                    <span className="fs-3">{state.serviceProvider.name}</span>
                  </Card.Text>

                  <Card.Text>
                    <Cities state={state} />
                  </Card.Text>
                </Card.Body>

                <Card.Footer>
                  <div className="d-grid gap-2">
                    <Button
                      className="link-tsr-red"
                      variant="tsr-red text-white"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth'
                        });
                        history.push(state.abbr.toLowerCase());
                      }}>
                      More Info
                    </Button>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

// Create state class that has getLink, hasCities, etc..
const Cities = ({ state }) => {
  const history = useHistory();

  const onClick = (e, link) => {
    e.preventDefault();

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    history.push(link);
  };

  if (!state || !state.cities || state.cities.length === 0) {

    return null;
  }

  return (
    state.cities.map((city, index) => {

      const s = state.abbr.toLowerCase();
      const c = city.name.toLowerCase();
      const link = `/${s}/${c}`;

      return (
        <span key={index}>
          <br />

          <a href={link} title={`${city.name}, ${state.abbr}`} className="link-secondary display-6" style={{ textDecoration: 'none' }} onClick={(e) => {

            onClick(e, link);
          }}>{city.name}, {state.abbr}</a>

          <br />

          <span>{city.zipCodes.map((zipCode, i) => {

            return (

              <a key={i} href={link} title={`${zipCode} - ${city.name}, ${state.abbr}`} style={{ color: 'grey' }} onClick={(e) => {

                onClick(e, link);
              }}>{zipCode}{i === city.zipCodes.length - 1 ? '' : ', '}</a>
            );
          })}</span>
        </span>
      );
    })
  );
};

export default PageFindProviderByState;
