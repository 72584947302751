// Third Party

// Components
import Meta from '../../components/Meta/Meta';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

// CSS
import './PageSewerLineReplacement.scss';

const pageTitle = `Sewer Line Replacement | Trenchless Sewer Line Replacement and Repair | Sewer Repair`;
const pageDescription = `Trenchless Sewer Repair – No Digging, No Tearing down your garage or porch to replace or repair your sewer line. Our Sewer Contractors will complete your Sewer Line Replacement without disturbing your beautiful property. Trenchless Sewer Line Replacement.`;
const pageCrumbs = [
  {
    to: '/',
    text: 'Home',
    title: 'Trenchless Sewer Repair | Trenchless Sewer Line Repairs | Sewer Pipe Repair'
  },
  {
    to: '/learn-about-services',
    text: 'Learn About Services',
    title: `Learn about Trenchless Sewer Repair Services | Trenchless Sewer Repair Information`
  },
  {
    to: '/learn-about-services/sewer-line-replacement',
    text: 'Sewer Line Replacement',
    title: pageTitle
  }
];

const PageSewerLineReplacement = () => {
  return (
    <div
      className="PageSewerLineReplacement animate__animated animate__zoomInRight"
      data-testid="PageSewerLineReplacement">
      <Meta title={pageTitle} desc={pageDescription} />

      <Breadcrumbs crumbs={pageCrumbs} />

      <h1 className="display-1">Trenchless Sewer Line Replacement</h1>

      <p>
        Inevitably, homeowners and businesses alike will need to have their sewer line replaced or
        repaired. Build up from aging, grease and oils, roots pushing into the sewer line walls,
        cracks and other issues can lead to sewer stoppages that can stink up your home or business
        and cause major problems. The good news is <b>sewer line replacement</b> no longer means
        having to tear up your entire yard or take down structures that lie in their path to install
        a new sewer line. Thanks to technological advances, sewer line replacement can now be
        accomplished with little or no disruption to your property.
      </p>

      <h2>No More Tearing Up Your Yard</h2>

      <p>
        Thanks to new technology known as trenchless sewer line replacement, destroying your
        landscape and digging 100’ trenches across your property to replace or repair your sewer
        line is a no longer necessary. In fact, you can have a brand new sewer line put in without
        the need for a tractor or backhoe. <b>Trenchless sewer line replacement</b> is an advanced
        process that runs brand new high density, extremely durable polyethylene sewer lines that
        are rated to last for 100+ years with a process called Pipe Bursting that completes the
        trenchless sewer line replacement underground leaving your yard virtually untouched and with
        a new flexible, streamlined highly durable sewer line that will leave you with a sewer
        excellence that belongs where it should be, out of your thoughts.
      </p>

      <h3>A Genius Technology That Brings Simplicity to Sewer Line Replacement</h3>

      <p>
        The invention of trenchless technology is genius, and with all genius inventions, the
        process is very simple. There are 2 types of trenchless sewer line replacement and your
        qualified local contractor can recommend which process is best for you after conducting a
        <b> sewer video inspection</b>. Sewer Pipe Bursting and Sewer Pipe Relining are the latest
        new trends for intelligent sewer line repair or replacement and any smart homeowner or
        business owner that knows about it will not allow a backhoe anywhere near their yard.
      </p>

      <p className="lead fw-bold">Pipe Bursting</p>

      <p>
        A new trenchless sewer repair process that pulls a brand new sewer line underneath your yard
        and structures at the rate of 3-5' per minute while simultaneously breaking up your old
        sewer line. This simple process can replace 100’ feet of sewer line in under an hour and
        leave you with a strong flexible, durable, and seamless new sewer line.
      </p>
    </div>
  );
};

export default PageSewerLineReplacement;
