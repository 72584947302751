import { Helmet } from 'react-helmet';

import './Meta.scss';

const Meta = ({ title, desc }) => {
  return (
    <div className="Meta" data-testid="Meta">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={desc} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
    </div>
  );
};

export default Meta;
