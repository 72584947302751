// Third Party
import { useState } from 'react';
import API from '@aws-amplify/api';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

// Components
import Meta from '../../components/Meta/Meta';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

// Context
import { UseData } from '../../context/DataProvider';

// CSS
import './PageGetListed.scss';

const pageTitle = `Trenchless-Sewer Repair – We're Looking for Certified Trenchless Contractors! Get Listed Now!`;
const pageDescription = `If you are a Contractor that is certified and trained in Trenchless Technology and meet the requirements, you may qualify to be listed on this website in your local area.`;
const pageCrumbs = [
  {
    to: '/',
    text: 'Home',
    title: 'Trenchless Sewer Repair | Trenchless Sewer Line Repairs | Sewer Pipe Repair'
  },
  {
    to: '/get-listed',
    text: 'Get Listed',
    title: pageTitle
  }
];
const defaultFormData = {
  name: '',
  company: '',
  phone: '',
  email: '',
  city: '',
  state: '',
  services: ''
};

/**
 * PageGetListed
 * TODO - Description
 * @returns <Component />
 */
const PageGetListed = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [formData, setFormData] = useState({ ...defaultFormData });
  const { states } = UseData();
  const statesList = states.map((x) => x.abbr).sort();

  const apiName = 'apiRestTsr';
  const path = '/form-request';
  const myInit = {
    body: {}
  };
  const formType = 'b-get-listed';

  const onChg = (e) => {
    e.persist();

    const { name, value } = e.currentTarget;

    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: value
      };
    });
  };

  const onReq = (e) => {
    e.preventDefault();

    setShowSpinner(true);

    myInit.body = {
      ...formData,
      source: window.location.pathname,
      formType
    };

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    API.post(apiName, path, myInit)
      .then(() => {
        setFormData({ ...defaultFormData });
        setShowSpinner(false);
        // TODO popup with success
      })
      .catch((error) => {
        console.error(error);
        setShowSpinner(false);
      });
  };

  if (showSpinner)
    return (
      <Button variant="secondary" disabled>
        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />

        <span>&nbsp;&nbsp;</span>

        <span>Submitting your request...</span>
      </Button>
    );

  return (
    <div
      className="PageGetListed animate__animated animate__zoomInRight mb-3"
      data-testid="PageGetListed">
      <Meta title={pageTitle} desc={pageDescription} />

      <Breadcrumbs crumbs={pageCrumbs} />

      <h1 className="text-black display-1">Are You A Contractor?</h1>

      <p className="lead">Certified in Trenchless Technology?</p>

      <p>
        If you are a Contractor that is certified and trained in Trenchless Technology and meet the
        requirements, you may qualify to be listed on this website in your local area.
      </p>

      <p>
        Just fill out the brief form below and representative will contact you with all the details.
      </p>

      <Form className="text-start" onSubmit={onReq}>
        <Form.Group className="mb-3" controlId="formName">
          <Form.Label>
            Name <span className="text-tsr-red">*</span>
          </Form.Label>

          <Form.Control
            required
            minLength={2}
            type="text"
            placeholder="Ex: John Doe"
            name="name"
            value={formData.name}
            onChange={onChg}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formCompany">
          <Form.Label>Company Name</Form.Label>

          <Form.Control
            minLength={2}
            type="text"
            placeholder="Ex: Smith Contractors, Inc."
            name="company"
            value={formData.company}
            onChange={onChg}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formPhone">
          <Form.Label>
            Phone <span className="text-tsr-red">*</span>
          </Form.Label>

          <Form.Control
            required
            minLength={10}
            type="tel"
            placeholder="Ex: (800) 555-1212"
            name="phone"
            value={formData.phone}
            onChange={onChg}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>
            Email <span className="text-tsr-red">*</span>
          </Form.Label>

          <Form.Control
            required
            type="email"
            placeholder="Ex: jdoe@test.com"
            name="email"
            value={formData.email}
            onChange={onChg}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formCity">
          <Form.Label>
            City <span className="text-tsr-red">*</span>
          </Form.Label>

          <Form.Control
            required
            minLength={3}
            type="text"
            placeholder="Ex: Chicago"
            name="city"
            value={formData.city}
            onChange={onChg}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formState">
          <Form.Label>
            State <span className="text-tsr-red">*</span>
          </Form.Label>

          <Form.Select size="lg" required name="state" value={formData.state} onChange={onChg}>
            {statesList.map((state, index) => {
              return <option key={index}>{state}</option>;
            })}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formServices">
          <Form.Label>Services</Form.Label>

          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Ex: List the services you offer here"
            name="services"
            value={formData.services}
            onChange={onChg}
          />
        </Form.Group>

        <div className="d-grid gap-2">
          <Button type="submit" variant="tsr-red text-white">
            Request Info
          </Button>
        </div>
      </Form>

      <hr className="text-md-tsr-blue" />
    </div>
  );
};

export default PageGetListed;
