// Third Party
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Components
import Search from '../components/Search/Search';
import Quote from '../components/Quote/Quote';
import GetListed from '../components/GetListed/GetListed';
import InfoCenter from '../components/InfoCenter/InfoCenter';

const LayoutMedium = ({ children }) => {
  return (
    <Container fluid>
      <Row>
        <Col md={8}>
          <Row xs={1} md={1} className="g-3">
            <Col>{children}</Col>

            <Col>
              <InfoCenter startHidden={false} />
            </Col>
          </Row>
        </Col>

        <Col md={4}>
          <Row xs={1} md={1} className="g-3">
            <Col className="mt-md-4">
              <Search startHidden={false} />
            </Col>

            <Col>
              <Quote startHidden={false} />
            </Col>

            <Col>
              <GetListed startHidden={true} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default LayoutMedium;
