// Components
import Meta from '../../components/Meta/Meta';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

// CSS
import './PageAbout.scss';

// Constants
const pageAboutTitle = `About Trenchless Sewer Repair`;
const page = {
  title: pageAboutTitle,
  desc: `Your Authority on Residential & Commercial Trenchless Sewer Repair Technology, Information and Contractors In Your Area.`,
  crumbs: [
    {
      to: '/',
      text: 'Home',
      title: 'Trenchless Sewer Repair | Trenchless Sewer Line Repairs | Sewer Pipe Repair'
    },
    {
      to: '/about',
      text: 'About',
      title: pageAboutTitle
    }
  ]
};

const PageAbout = () => {
  return (
    <div className="PageAbout animate__animated animate__zoomInRight" data-testid="PageAbout">
      <Meta title={page.title} desc={page.desc} />

      <Breadcrumbs crumbs={page.crumbs} />

      <h1>About Us</h1>

      <p className="lead">Trenchless Sewer Repair</p>

      <p>
        This website was assembled by us to provide useful information to educate and inform you
        about options available for your sewer line repair and replacement needs. We have taken the
        time to gather all the latest information and procedures available to you while looking for
        the right sewer contractor in your area to handle your project.
      </p>

      <h2>Contractors on This Website</h2>

      <p>
        The Trenchless Sewer Contractors you will find on this website were prequalified by us to
        insure they are experts in the field of trenchless sewer repair technology and methods. We
        confirmed that they have at least 5 years prior experience, are top rated BBB members, and
        provide trenchless sewer repairs directly to the consumer and are not outsourcing or
        subcontracting the work to another company.
      </p>
    </div>
  );
};

export default PageAbout;
