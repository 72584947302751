// Third Party

// Components
import Meta from '../../components/Meta/Meta';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

// CSS
import './PageTrenchlessSewerReplacement.scss';

const pageTitle = `Trenchless Pipe Bursting | Trenchless Sewer Pipe Bursting | Pipe Bursting Contractors`;
const pageDescription = `Trenchless Sewer Repair – Your Source for Trenchless Pipe Bursting & Sewer Repair and Replacement Technology. Find Your Local Qualified Trenchless Pipe Bursting Contractors and Pipe Bursting Experts. Trenchless Pipe Bursting.`;
const pageCrumbs = [
  {
    to: '/',
    text: 'Home',
    title: 'Trenchless Sewer Repair | Trenchless Sewer Line Repairs | Sewer Pipe Repair'
  },
  {
    to: '/learn-about-services',
    text: 'Learn About Services',
    title: `Learn about Trenchless Sewer Repair Services | Trenchless Sewer Repair Information`
  },
  {
    to: '/learn-about-services/trenchless-sewer-replacement',
    text: 'Trenchless Sewer Replacement',
    title: pageTitle
  }
];

const PageTrenchlessSewerReplacement = () => {
  return (
    <div
      className="PageTrenchlessSewerReplacement animate__animated animate__zoomInRight"
      data-testid="PageTrenchlessSewerReplacement">
      <Meta title={pageTitle} desc={pageDescription} />

      <Breadcrumbs crumbs={pageCrumbs} />

      <h1 className="display-1">Trenchless Sewer Replacement</h1>

      <p>
        eplacing your sewer line does not have to be a big ordeal anymore. Digging up your beautiful
        yard, knocking down structures like garages and porches just to replace or repair your sewer
        line is now a thing of the past. New Technological advances in sewer line repairs means
        great news for homeowners and businesses alike that are in need of sewer line repairs or
        replacement.
      </p>

      <h2>Keep Your Landscape Beautiful and Green</h2>

      <p>
        Thanks to trenchless sewer replacement technology, you can have your sewer line repaired or
        replaced while leaving your landscape just the way it is, beautiful and green. Rather than
        having to dig a long trench across your entire yard, trenchless sewer repair technology now
        allows for a new sewer pipe to be fed UNDERNEATH your yard leaving your landscape and
        structures untouched. This is accomplished with two new methods called pipe bursting and
        pipe relining.
      </p>

      <h3>New Trenchless Sewer Replacement Methods</h3>

      <p className="lead fw-bold">Pipe Bursting</p>

      <p>
        This trenchless sewer replacement method consists of pulling a new sewer line through the
        ground while simultaneously breaking up your old sewer pipe. Here's how it works: Two very
        small access holes, usually around 2'x3' are dug at each end of your old sewer line. A heavy
        cable is fed through one access hole through the entire length of you old sewer pipe and
        attached to a cone shaped tool on the other end called a bursting head. This bursting head
        is securely attached to your new sewer line. A powerful hydraulic winch pulls the bursting
        head with your new sewer line in tow and easily breaks up your old sewer pipe while pulling
        in the new sewer pipe behind it.
      </p>

      <p className="lead fw-bold">Pipe Relining</p>

      <p>
        This trenchless sewer replacement method consists of installing a 'pipe within a pipe' and
        usually requires no digging at all. Here's how it works: You old sewer line is accessed
        usually through and access point called a clean-out. A new flexible material lined with
        epoxy is fed through the entire length your old sewer pipe. A bladder is then inserted the
        full length of the pipe and inflated with high pressure air. This forces the new liner
        tightly against the inside walls of your old pipe and is fused there with the epoxy sealing
        all the cracks and leaving an extremely hard and smooth surface to restore clean flow
        through your sewer line.
      </p>
    </div>
  );
};

export default PageTrenchlessSewerReplacement;
