import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import './Breadcrumbs.scss';

const Breadcrumbs = ({ crumbs }) => {
  const history = useHistory();

  crumbs = Array.isArray(crumbs) ? crumbs : [];

  if (crumbs.length === 0) return <div className="Breadcrumbs" data-testid="Breadcrumbs"></div>;

  return (
    <div className="Breadcrumbs" data-testid="Breadcrumbs" style={{ marginTop: '1rem' }}>
      <Router>
        <Breadcrumb>
          {crumbs.map((crumb, index) => {
            return (
              <Breadcrumb.Item
                key={index}
                title={crumb.title}
                href={crumb.to}
                active={crumbs.length === index + 1}
                onClick={(e) => {
                  e.preventDefault();
                  history.push(crumb.to);
                }}>
                {crumb.text}
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
      </Router>
    </div>
  );
};

export default Breadcrumbs;
