// Third Party
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';

// CSS
import './NavSite.scss';

// TODO move to redux state? used outside of this component?
const companyName = 'Trenchless Sewer Repair';
const companySlogan = 'Brand New Sewer Pipe Without Digging!';
const linkAbout = '/about';
const linkLearnAboutServices = '/learn-about-services';

/**
 * NavSite
 * Should load only once as it's outside of the router switch
 * @param {{ pageWidth: num }} args
 * @returns { <Component /> }
 */
const NavSite = ({ pageWidth }) => (

  <Navbar
    className="NavSite"
    data-testid="NavSite"
    bg="primary"
    variant="dark"
    expand="md"
    collapseOnSelect={true}>

    <Container fluid={pageWidth > 1024 ? false : true}>

      <Navbar.Brand as={Link} href="/" to="/">

        {companyName}
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />

      <Navbar.Collapse id="basic-navbar-nav">

        <Nav className="me-auto">

          <Nav.Link as={Link} href={linkAbout} to={linkAbout}>About</Nav.Link>

          <Nav.Link as={Link} href={linkLearnAboutServices} to={linkLearnAboutServices}>
            Learn about Services
          </Nav.Link>
        </Nav>

        <Nav className="d-none d-lg-block">

          <Navbar.Text>

            <span className="fw-bold text-white">{companySlogan}</span>
          </Navbar.Text>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);

export default NavSite;
