import './Slogan.scss';

const sSlogan = 'Brand New Sewer Pipe Without Digging!';

const Slogan = () => {
  return (
    <h1 className="Slogan text-center text-primary" data-testid="Slogan">
      {sSlogan}
    </h1>
  );
};

export default Slogan;
