// Third party
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Logger } from 'aws-amplify';

// Pages
import PagePrivacyPolicy from './pages/PagePrivacyPolicy/PagePrivacyPolicy';
import PageAbout from './pages/PageAbout/PageAbout';
import PageHome from './pages/PageHome/PageHome';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import PageLearnAboutServices from './pages/PageLearnAboutServices/PageLearnAboutServices';
import PageTrenchlessPipeLining from './pages/PageTrenchlessPipeLining/PageTrenchlessPipeLining';
import PageTrenchlessPipeBursting from './pages/PageTrenchlessPipeBursting/PageTrenchlessPipeBursting';
import PageTrenchlessSewerReplacement from './pages/PageTrenchlessSewerReplacement/PageTrenchlessSewerReplacement';
import PageSewerLineReplacement from './pages/PageSewerLineReplacement/PageSewerLineReplacement';
import PageSewerReplacementCost from './pages/PageSewerReplacementCost/PageSewerReplacementCost';
import PageWhyGoWithTrenchless from './pages/PageWhyGoWithTrenchless/PageWhyGoWithTrenchless';
import PageSewerRepairTechnology from './pages/PageSewerRepairTechnology/PageSewerRepairTechnology';
import PagePipeBurstingServiceProviders from './pages/PagePipeBurstingServiceProviders/PagePipeBurstingServiceProviders';
import PagePipeLiningCompanies from './pages/PagePipeLiningCompanies/PagePipeLiningCompanies';
import PageFindProviderByState from './pages/PageFindProviderByState/PageFindProviderByState';
import PageGetListed from './pages/PageGetListed/PageGetListed';
import PageState from './pages/PageState/PageState';
import PageCity from './pages/PageCity/PageCity';

// Components
import NavSite from './components/NavSite/NavSite';
import FooterSite from './components/FooterSite/FooterSite';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import LayoutSmall from './layout/LayoutSmall';
import LayoutMedium from './layout/LayoutMedium';
import LayoutLarge from './layout/LayoutLarge';

// Context
import { UseData } from './context/DataProvider';

// CSS
import './App.scss';

const logger = new Logger('App.js');

/**
 * App
 * Primary app component rendered to React
 * Stitches together the Layout, Router, Site navigation, Footer, and Scroll to Top feature
 * @returns { <Component /> }
 */
const App = () => {

  logger.debug('rendered');

  return (

    // NOTE className and data-testid are used in testing
    <div className="App" data-testid="App">

      <Router>

        <NavSite />

        <main>

          <LayoutSwitch />
        </main>

        <footer className="text-center bg-black text-white mt-2">

          <FooterSite />
        </footer>
      </Router>

      <ScrollToTop />
    </div>
  );
};

/**
 * LayoutSwitch
 * Currently the best way to manage the different position layouts
 * TODO - look for a better way
 * @param { pageWidth: num } param0
 * @returns { <Component /> }
 */
const LayoutSwitch = () => {
  const { width } = UseData();

  if (width <= 576) {
    return (
      <LayoutSmall>
        <LayoutRoutes />
      </LayoutSmall>
    );
  }

  if (width > 576 && width <= 1383) {
    return (
      <LayoutMedium>
        <LayoutRoutes />
      </LayoutMedium>
    );
  }

  return (
    <LayoutLarge>
      <LayoutRoutes />
    </LayoutLarge>
  );
};

/**
 * Easiest way to pass all the routes into each layout
 * @returns { <Component /> }
 */
const LayoutRoutes = () => {

  const { states } = UseData();

  return (

    <Switch>

      <Route
        exact
        path="/learn-about-services/sewer-line-replacement"
        component={PageSewerLineReplacement}
      />

      <Route
        exact
        path="/learn-about-services/trenchless-sewer-replacement"
        component={PageTrenchlessSewerReplacement}
      />

      <Route
        exact
        path="/learn-about-services/trenchless-pipe-bursting"
        component={PageTrenchlessPipeBursting}
      />

      <Route
        exact
        path="/learn-about-services/trenchless-pipe-lining"
        component={PageTrenchlessPipeLining}
      />

      <Route exact path="/learn-about-services" component={PageLearnAboutServices} />

      <Route exact path="/find-provider-by-state" component={PageFindProviderByState} />

      <Route exact path="/pipe-lining-companies" component={PagePipeLiningCompanies} />

      <Route
        exact
        path="/pipe-bursting-service-providers"
        component={PagePipeBurstingServiceProviders}
      />

      <Route exact path="/sewer-repair-technology" component={PageSewerRepairTechnology} />

      <Route exact path="/why-go-with-trenchless" component={PageWhyGoWithTrenchless} />

      <Route exact path="/sewer-replacement-cost" component={PageSewerReplacementCost} />

      <Route exact path="/get-listed" component={PageGetListed} />

      <Route exact path="/privacy-policy" component={PagePrivacyPolicy} />

      <Route exact path="/about" component={PageAbout} />

      {states.map((state, index) => {

        return (

          <Route
            key={index}
            exact
            path={`/${state.abbr.toLowerCase()}`}
            component={PageState}
          />
        );
      })}

      {states.map((state, index) => {

        return (

          <Route
            key={index}
            exact
            path={`/${state.abbr.toLowerCase()}/:city`}
            component={PageCity}
          />
        );
      })}

      <Route exact path="/" component={PageHome} />

      <Route component={PageNotFound} />
    </Switch>
  );
};

export default App;
