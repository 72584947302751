// Third Party

// Components
import Meta from '../../components/Meta/Meta';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

// CSS
import './PagePipeBurstingServiceProviders.scss';

const pageTitle = `Trenchless Pipe Bursting | Trenchless Sewer Pipe Bursting | Pipe Bursting Service Providers`;
const pageDescription = `Trenchless Sewer Repair – Your Source for Trenchless Pipe Bursting & Sewer Repair and Replacement Technology. Find Your Local Qualified Trenchless Pipe Bursting Contractors and Pipe Bursting Experts. Trenchless Pipe Bursting.`;
const pageCrumbs = [
  {
    to: '/',
    text: 'Home',
    title: 'Trenchless Sewer Repair | Trenchless Sewer Line Repairs | Sewer Pipe Repair'
  },
  {
    to: '/pipe-bursting-service-providers',
    text: 'Pipe Bursting Service Providers',
    title: pageTitle
  }
];

/**
 * PagePipeBurstingServiceProviders
 * TODO - Description
 * @returns <Component />
 */
const PagePipeBurstingServiceProviders = () => {
  return (
    <div
      className="PagePipeBurstingServiceProviders animate__animated animate__zoomInRight"
      data-testid="PagePipeBurstingServiceProviders">
      <Meta title={pageTitle} desc={pageDescription} />

      <Breadcrumbs crumbs={pageCrumbs} />

      <h1 className="display-1">Traditional 'Dig and Replace 'Method Costs</h1>

      <p>
        Trenchless Pipe Bursting is a new way to have your sewer line replaced with very minimal
        disruption to your yard. This advanced method of <b>trenchless sewer line replacement</b>{' '}
        calls for only 2 very small access holes to be dug at each end of your sewer line. A heavy
        cable is fed through the entire length of your old sewer line and attached to a tool called
        a bursting head on the other end. The bursting head is a powerful cone shaped tool that when
        pulled through your old sewer line by the cable, breaks up the old pipe while pulling a
        brand new one in behind it. The bursting head is pulled through with a very powerful
        hydraulic winch that can pull with up to 30 tons of pressure, plenty of strength to easily
        and cleanly power through any type of old sewer line.
      </p>

      <h2>Trenchless Pipe Bursting</h2>

      <p>
        Trenchless Pipe Bursting has become the most common method of <b>trenchless sewer repair</b>{' '}
        as it requires minimal digging and can run 100’s of feet of new sewer line in one day. The
        new pipe is made of high density polyethylene that is extremely durable, flexible and
        seamless meaning there are no joints in it. Old jointed pipes can allow root intrusion,
        cracking, and weak spots that your new polyethylene pipe will not. The new pipe is rated to
        last for 100+ years.
      </p>

      <h3>Pipe Bursting for Residential Sewer Lines</h3>

      <p>
        Most residential sewer pipes are between 3'- 6'' diameter making trenchless pipe bursting an
        easy job. With new <b>trenchless pipe bursting</b> technology, even pipes as wide as 56' can
        be replaced using this method. For residential applications it is by far the easiest and
        most economical way to replace your old sewer lines. There is no longer any need to tear up
        your yard or structures like garages, porches, sheds that may lie in the path of your sewer
        line, with trenchless pipe bursting technology you can now pull a new a brand new high grade
        polyethylene sewer pipe right underneath them.
      </p>
    </div>
  );
};

export default PagePipeBurstingServiceProviders;
