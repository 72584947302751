import './ExternalLink.scss';

const ExternalLink = ({ anchorClass, anchorTitle, anchorHREF, iconClass, iconStyle }) => {
  return (
    <a
      className={`ExternalLink ${anchorClass}`}
      data-testid="ExternalLink"
      title={anchorTitle}
      target="_blank"
      rel="noreferrer"
      href={anchorHREF}>
      <i className={`bi ${iconClass}`} style={iconStyle} />
    </a>
  );
};

export default ExternalLink;
