// Third party
import { Logger } from 'aws-amplify';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import InputGroup from 'react-bootstrap/InputGroup';
import Card from 'react-bootstrap/Card';
import FormControl from 'react-bootstrap/FormControl';

// CSS
import './Search.scss';

// TODO - move to redux?
const shadowClass = 'shadow rounded';

// TODO LOGGER
const logger = new Logger('components/Search/Search.js');

const Search = ({ startHidden }) => {
  const [hide, setHide] = useState(!!startHidden);

  const onClickHeader = () => {
    setHide((prevState) => !prevState);
  };

  logger.debug('rendered Search');

  return (
    <Card className={`Search text-center ${shadowClass}`} data-testid="Search">
      <Card.Header className="bg-tsr-blue text-white" onClick={onClickHeader}>
        Find a Qualified Service Provider
      </Card.Header>

      <SearchBody hide={hide} />
    </Card>
  );
};

const SearchBody = ({ hide }) => {
  if (hide) return null;

  logger.debug('rendered SearchBody');

  return (
    <Card.Body>
      <InputGroup>
        <SearchInput />

        <InputGroup.Text id="basic-addon1">
          <i className="bi bi-search"></i>
        </InputGroup.Text>
      </InputGroup>
    </Card.Body>
  );
};

const SearchInput = () => {

  const history = useHistory();

  useEffect(() => {
    const autoCompleteInput = new window.google.maps.places.Autocomplete(
      document.getElementById('autoComplete'),
      {
        types: ['geocode'],
      },
    );

    const funcListener = () => {
      let place = autoCompleteInput.getPlace();
      let state;
      let city;

      for (let ac of place.address_components) {
        let type = ac.types.shift();

        switch (type) {
          case 'administrative_area_level_1':
            state = ac.short_name;
            break;
          case 'locality':
            city = ac.short_name;
            break;
          default:
            break;
        }
      }

      if (city) {
        city = city.replace(/\s+/g, '-').toLowerCase();


        history.push(`/${state.toLowerCase()}/${city}`);
      } else {
        history.push(`/${state.toLowerCase()}`);
      }
    };

    autoCompleteInput.setFields(['address_component']);
    autoCompleteInput.setComponentRestrictions({ country: ['us'] });
    autoCompleteInput.addListener('place_changed', funcListener);

    return () => {

      try {
        autoCompleteInput.removeListener('place_changed', funcListener);
      } catch (error) {
        console.error(error);
      }
    };
  }, [history]);

  logger.debug('rendered SearchBody');

  return (
    <FormControl
      id="autoComplete"
      placeholder="Search by Zip, City or State"
      aria-label="search"
      aria-describedby="basic-addon1"
    />
  );
};

export default Search;
