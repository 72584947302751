// Third Party
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardGroup from 'react-bootstrap/CardGroup';

// Components
import Slogan from '../components/Slogan/Slogan';
import Search from '../components/Search/Search';
import Quote from '../components/Quote/Quote';
import GetListed from '../components/GetListed/GetListed';
import InfoCenter from '../components/InfoCenter/InfoCenter';

const LayoutSmall = ({ children }) => {
  return (
    <Container fluid>
      <Slogan />

      <CardGroup>
        <Search startHidden={false} />

        <Quote startHidden={true} />
      </CardGroup>

      <Row>
        <Col>{children}</Col>
      </Row>

      <CardGroup>
        <InfoCenter startHidden={false} />

        <GetListed startHidden={false} />
      </CardGroup>
    </Container>
  );
};

export default LayoutSmall;
