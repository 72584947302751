// Third party
import { useState } from 'react';
import API from '@aws-amplify/api';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

// CSS
import './Quote.scss';

// Context
import { UseData } from '../../context/DataProvider';

// TODO - move to redux?
const shadowClass = 'shadow rounded';

const defaultFormData = {
  name: '',
  phone: '',
  email: '',
  street: '',
  city: '',
  state: '',
  zip: '',
  commercial: false,
  residential: false,
  municipal: false,
  estimateReq: false,
  infoReq: false,
  comments: ''
};

const Quote = ({ startHidden }) => {
  const [hide, setHide] = useState(!!startHidden);

  const onClickHeader = () => {
    setHide((prevState) => !prevState);
  };

  return (
    <Card className={`Quote text-center ${shadowClass}`} data-testid="Quote">
      <Card.Header className="bg-tsr-red text-white" onClick={onClickHeader}>
        Get a Quote Now!
      </Card.Header>

      <QuoteBody hide={hide} />
    </Card>
  );
};

const QuoteBody = ({ hide }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [formData, setFormData] = useState({ ...defaultFormData });
  const { states } = UseData();
  const statesList = states.map((x) => x.abbr).sort();

  const apiName = 'apiRestTsr';
  const path = '/form-request';
  const myInit = {
    body: {}
  };
  const formType = 'a-quote';

  const onChg = (e) => {
    e.persist();

    const { name, value, checked } = e.currentTarget;
    const boolFields = ['commercial', 'residential', 'municipal', 'estimateReq', 'infoReq'];

    setFormData((prevState) => {
      return {
        ...prevState,
        [name]: boolFields.includes(name) ? checked : value
      };
    });
  };

  const onReq = (e) => {
    e.preventDefault();

    setShowSpinner(true);

    myInit.body = {
      ...formData,
      source: window.location.pathname,
      formType
    };

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    API.post(apiName, path, myInit)
      .then(() => {
        setFormData({ ...defaultFormData });
        setShowSpinner(false);
        // TODO popup with success
      })
      .catch((error) => {
        console.error(error);
        setShowSpinner(false);
      });
  };

  if (hide) return null;

  if (showSpinner)
    return (
      <Button variant="secondary" disabled>
        <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />

        <span>&nbsp;&nbsp;</span>

        <span>Submitting your request...</span>
      </Button>
    );

  return (
    <Card.Body>
      <Card.Title>Get a Quick, Free, Sewer Repair Estimate!</Card.Title>

      <Card.Text>
        Just fill out the form below and a representative will contact as soon as possible.
      </Card.Text>

      <Form className="text-start" onSubmit={onReq}>
        <Form.Group className="mb-3" controlId="formName">
          <Form.Label>
            Name <span className="text-tsr-red">*</span>
          </Form.Label>

          <Form.Control
            required
            minLength={2}
            type="text"
            placeholder="Ex: John Doe"
            name="name"
            value={formData.name}
            onChange={onChg}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formPhone">
          <Form.Label>
            Phone <span className="text-tsr-red">*</span>
          </Form.Label>

          <Form.Control
            required
            minLength={10}
            type="tel"
            placeholder="Ex: (800) 555-1212"
            name="phone"
            value={formData.phone}
            onChange={onChg}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>
            Email <span className="text-tsr-red">*</span>
          </Form.Label>

          <Form.Control
            required
            type="email"
            placeholder="Ex: jdoe@test.com"
            name="email"
            value={formData.email}
            onChange={onChg}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formStreet">
          <Form.Label>
            Street Address <span className="text-tsr-red">*</span>
          </Form.Label>

          <Form.Control
            required
            minLength={5}
            type="text"
            placeholder="Ex: 23 Jordan Way Unit 1"
            name="street"
            value={formData.street}
            onChange={onChg}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formCity">
          <Form.Label>
            City <span className="text-tsr-red">*</span>
          </Form.Label>

          <Form.Control
            required
            minLength={3}
            type="text"
            placeholder="Ex: Chicago"
            name="city"
            value={formData.city}
            onChange={onChg}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formState">
          <Form.Label>
            State <span className="text-tsr-red">*</span>
          </Form.Label>

          <Form.Select size="lg" required name="state" value={formData.state} onChange={onChg}>
            {statesList.map((state, index) => {
              return <option key={index}>{state}</option>;
            })}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formZip">
          <Form.Label>
            Zip Code <span className="text-tsr-red">*</span>
          </Form.Label>

          <Form.Control
            required
            minLength={5}
            maxLength={5}
            type="text"
            placeholder="Ex: 10001"
            name="zip"
            value={formData.zip}
            onChange={onChg}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formCheckboxCommercial">
          <Form.Check
            type="checkbox"
            label="Commercial"
            name="commercial"
            value={formData.commercial}
            onChange={onChg}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formCheckboxResidential">
          <Form.Check
            type="checkbox"
            label="Residential"
            name="residential"
            value={formData.residential}
            onChange={onChg}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formCheckboxMunicipal">
          <Form.Check
            type="checkbox"
            label="Municipal"
            name="municipal"
            value={formData.municipal}
            onChange={onChg}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formCheckboxEstimate">
          <Form.Check
            type="checkbox"
            label="Would Like an Estimate"
            name="estimateReq"
            value={formData.estimateReq}
            onChange={onChg}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formCheckboxInfo">
          <Form.Check
            type="checkbox"
            label="Just Looking for Info"
            name="infoReq"
            value={formData.infoReq}
            onChange={onChg}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formComments">
          <Form.Label>Comments</Form.Label>

          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Ex: place any extra comments or questions here"
            name="comments"
            value={formData.comments}
            onChange={onChg}
          />
        </Form.Group>

        <div className="d-grid gap-2">
          <Button type="submit" variant="tsr-red text-white">
            Request Estimate
          </Button>
        </div>
      </Form>
    </Card.Body>
  );
};

export default Quote;
