// Third party
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

// CSS
import './InfoCenter.scss';

// TODO - move to redux?
const shadowClass = 'shadow rounded';
const linkLearnAboutServices = '/learn-about-services';
const linkSewerReplacementCost = '/sewer-replacement-cost';
const linkWhyGoWithTrenchless = '/why-go-with-trenchless';
const linkSewerRepairTechnology = '/sewer-repair-technology';
const linkTrenchlessPipeBursting = `${linkLearnAboutServices}/trenchless-pipe-bursting`;
const linkTrenchlessSewerReplacement = `${linkLearnAboutServices}/trenchless-sewer-replacement`;
const linkSewerLineReplacement = `${linkLearnAboutServices}/sewer-line-replacement`;
const linkTrenchlessPipeLining = `${linkLearnAboutServices}/trenchless-pipe-lining`;
const linkPipeBurstingServiceProviders = '/pipe-bursting-service-providers';
const linkPipeLiningCompanies = '/pipe-lining-companies';
const linkFindYourServiceProvider = '/find-provider-by-state';

const InfoCenter = ({ startHidden }) => {
  const [hide, setHide] = useState(!!startHidden);

  const onClickHeader = () => {
    setHide((prevState) => !prevState);
  };

  return (
    <Card className={`InfoCenter text-center ${shadowClass}`} data-testid="InfoCenter">
      <Card.Header className="bg-tsr-blue text-white" onClick={onClickHeader}>
        Sewer Repair Information Center
      </Card.Header>

      <InfoCenterBody hide={hide} />
    </Card>
  );
};

const InfoCenterBody = ({ hide }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  if (hide) return null;

  return (
    <Card.Body className="text-start">
      <Card.Text>
        Click any of the links below to learn more about Trenchless Sewer Repair
      </Card.Text>

      <ListGroup variant="flush">
        <ListGroup.Item
          as={Link}
          to={linkSewerReplacementCost}
          onClick={() => {
            scrollToTop();
          }}>
          Sewer Replacement Cost
        </ListGroup.Item>

        <ListGroup.Item
          as={Link}
          to={linkWhyGoWithTrenchless}
          onClick={() => {
            scrollToTop();
          }}>
          Why Go With Trenchless?
        </ListGroup.Item>

        <ListGroup.Item
          as={Link}
          to={linkSewerRepairTechnology}
          onClick={() => {
            scrollToTop();
          }}>
          Sewer Repair Technology
        </ListGroup.Item>

        <ListGroup.Item
          as={Link}
          to={linkTrenchlessPipeBursting}
          onClick={() => {
            scrollToTop();
          }}>
          Trenchless Pipe Bursting
        </ListGroup.Item>

        <ListGroup.Item
          as={Link}
          to={linkTrenchlessSewerReplacement}
          onClick={() => {
            scrollToTop();
          }}>
          Trenchless Sewer Replacement
        </ListGroup.Item>

        <ListGroup.Item
          as={Link}
          to={linkSewerLineReplacement}
          onClick={() => {
            scrollToTop();
          }}>
          Sewer Line Replacement
        </ListGroup.Item>

        <ListGroup.Item
          as={Link}
          to={linkTrenchlessPipeLining}
          onClick={() => {
            scrollToTop();
          }}>
          Trenchless Pipe Lining
        </ListGroup.Item>

        <ListGroup.Item
          as={Link}
          to={linkPipeBurstingServiceProviders}
          onClick={() => {
            scrollToTop();
          }}>
          Pipe Bursting Service Providers
        </ListGroup.Item>

        <ListGroup.Item
          as={Link}
          to={linkPipeLiningCompanies}
          onClick={() => {
            scrollToTop();
          }}>
          Pipe Lining Companies
        </ListGroup.Item>

        <ListGroup.Item
          as={Link}
          to={linkFindYourServiceProvider}
          className="d-flex justify-content-between align-items-center"
          onClick={() => {
            scrollToTop();
          }}>
          Find Your Provider By State
        </ListGroup.Item>
      </ListGroup>
    </Card.Body>
  );
};

export default InfoCenter;
