// Third Party

// Components
import Meta from '../../components/Meta/Meta';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

// CSS
import './PageSewerRepairTechnology.scss';

const pageTitle = `Trenchless Sewer Repair Technology | Sewer Replacement Technology`;
const pageDescription = `Trenchless Sewer Repair – Your Authority on the Latest Trends in Sewer Repair and Replacement Technology. Learn Everything There Is To Know About Residential and Commercial Sewer Line Repair Technology.`;
const pageCrumbs = [
  {
    to: '/',
    text: 'Home',
    title: 'Trenchless Sewer Repair | Trenchless Sewer Line Repairs | Sewer Pipe Repair'
  },
  {
    to: '/sewer-repair-technology',
    text: 'Sewer Repair Technology',
    title: pageTitle
  }
];

/**
 * PageSewerRepairTechnology
 * TODO - Description
 * @returns <Component />
 */
const PageSewerRepairTechnology = () => {
  return (
    <div
      className="PageSewerRepairTechnology animate__animated animate__zoomInRight"
      data-testid="PageSewerRepairTechnology">
      <Meta title={pageTitle} desc={pageDescription} />

      <Breadcrumbs crumbs={pageCrumbs} />

      <h1 className="display-1">Trenchless Sewer Repair Technology</h1>

      <p>
        Remember the old days when repairing or replacing your sewer line meant calling in the
        backhoes, crew of diggers, and contractors to pull down your garage, pool, porch, or
        whatever else may lie in the path of your underground sewer line so it can be repaired?
        Thanks to new breakthroughs in <b>sewer line replacement</b> and repair technology, this
        nightmare is a thing of the past. There is no need to disturb your yard or beautiful
        landscape, tear down your garage, ruin your driveway, or lay a finger on anything else that
        lies in the path of your damaged underground sewer line anymore as there is a new modern
        method available to you.
      </p>

      <p>
        Sewer Pipe Relining is the latest technology in making trenchless sewer pipe repairs or
        replacements as it is faster cheaper, and requires very little digging. This process is used
        to reline the interior of your old sewer line leaving a new 'pipe within a pipe' and in most
        cases can be completed in just one day.
      </p>

      <h2>No More Digging Up Your Yard</h2>

      <p>
        Just as everything else in the world today is advancing rapidly, so is the world of sewer
        repair. Using a new method called <b>trenchless sewer repair</b>, your sewer line can now be
        replaced or repaired without having to dig up your yard. There are two different methods
        used for <b>trenchless sewer line replacements</b> and they are called Pipe Bursting and
        Pipe Relining.
      </p>

      <h3>Sewer Pipe Bursting</h3>

      <p>
        With this method, only 2 small access holes approximately 2' x 3' needs to be dug at each
        end of your sewer line. A cable is fed through the entire length of your sewer line from one
        end, and secured to a powerful cone shaped tool on the other end called a bursting head.
        This bursting head is securely fastened to a new sewer pipe. A powerful machine then pulls
        the bursting head through you old pipe and breaks it up under the ground, while
        simultaneously pulling a brand new sewer pipe in behind it. Most of these machines can pull
        up to 10’ of new sewer line in per minute and can be completed in just one day.
      </p>

      <h4>Sewer Pipe Relining</h4>

      <p>
        With this <b>trenchless sewer repair</b> method , in most cases, no digging needs to be
        performed at all. A 'pipe within a pipe' is installed by relining your old sewer line with a
        new epoxy coated fabric that is fed through your sewer pipe usually from an access point
        called a clean-out. Then a long bladder is fed through and expanded with compressed air or
        steam fusing the new liner to your old pipe. This will reinforce and seal all the cracks and
        leave a smooth, hard surface to restore flow and functionality to your sewer line.
      </p>
    </div>
  );
};

export default PageSewerRepairTechnology;
