// Third party
import { useHistory } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

// Components
import Meta from '../../components/Meta/Meta';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

// CSS
import './PageLearnAboutServices.scss';

// TODO - move to redux?
const linkLearnAboutServices = '/learn-about-services';
const linkTrenchlessPipeLining = `${linkLearnAboutServices}/trenchless-pipe-lining`;
const linkTrenchlessPipeBursting = `${linkLearnAboutServices}/trenchless-pipe-bursting`;
const linkTrenchlessSewerReplacement = `${linkLearnAboutServices}/trenchless-sewer-replacement`;
const linkSewerLineReplacement = `${linkLearnAboutServices}/sewer-line-replacement`;
const cards = [
  {
    header: 'Trenchless Pipe Lining',
    title: 'Cured In Place Pipe (CIPP)',
    text: `With the progression of technology in the trenchless sewer pipe industry, inspecting,
    repairing or replacing sewer pipes has never been more cost effective or as efficient
    as it is today.`,
    link: linkTrenchlessPipeLining,
    footerText: 'Click for more Trenchless Pipe Lining information'
  },
  {
    header: 'Trenchless Pipe Bursting',
    title: 'Pipe Bursting for Residential Sewer Lines',
    text: `Most residential sewer pipes are between 3'- 6'' diameter making trenchless pipe bursting an easy job. With new trenchless pipe bursting technology, even pipes as wide as 56' can be replaced using this method. `,
    link: linkTrenchlessPipeBursting,
    footerText: 'Click for more Trenchless Pipe Bursting information'
  },
  {
    header: 'Trenchless Sewew Replacement',
    title: 'Keep Your Landscape Beautiful and Green',
    text: `Thanks to trenchless sewer replacement technology, you can have your sewer line repaired or replaced while leaving your landscape just the way it is, beautiful and green.`,
    link: linkTrenchlessSewerReplacement,
    footerText: 'Click for more Trenchless Sewew Replacement information'
  },
  {
    header: 'Sewew Line Replacement',
    title: 'No More Tearing Up Your Yard',
    text: `Thanks to new technology known as trenchless sewer line replacement, destroying your landscape and digging 100’ trenches across your property to replace or repair your sewer line is a no longer necessary. `,
    link: linkSewerLineReplacement,
    footerText: 'Click for more Sewew Line Replacement information'
  }
];
const pageTitle = `Learn about Trenchless Sewer Repair Services | Trenchless Sewer Repair Information`;
const pageDescription = `Your Authority on Residential & Commercial Trenchless Sewer Repair Technology, Information and Contractors In Your Area. Trenchless Sewer Repair`;
const pageCrumbs = [
  {
    to: '/',
    text: 'Home',
    title: 'Trenchless Sewer Repair | Trenchless Sewer Line Repairs | Sewer Pipe Repair'
  },
  {
    to: linkLearnAboutServices,
    text: 'Learn about Services',
    title: pageTitle
  }
];

/**
 * PageLearnAboutServices
 * Top-level page for Learn About Services
 * Links to all lower-level pages
 * @returns <Component />
 */
const PageLearnAboutServices = () => {
  const history = useHistory();

  const goTo = (page) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    history.push(page);
  };

  return (
    <div
      className="PageLearnAboutServices animate__animated animate__zoomInRight"
      data-testid="PageLearnAboutServices">
      <Meta title={pageTitle} desc={pageDescription} />

      <Breadcrumbs crumbs={pageCrumbs} />

      <h1 className="display-1 text-secondary">Learn about Trenchless Sewer Repair</h1>

      <p className="lead">Trenchless Sewer Repair information Cards</p>

      <Row xs={1} md={2} className="g-4">
        {cards.map((card, index) => (
          <Col key={index}>
            <Card>
              <Card.Header className="bg-secondary text-white">{card.header}</Card.Header>

              <Card.Body>
                <Card.Title>{card.title}</Card.Title>

                <Card.Text>{card.text}</Card.Text>
              </Card.Body>

              <Card.Footer
                onClick={() => {
                  goTo(card.link);
                }}>
                <div className="d-grid gap-2">
                  <Button variant="link">{card.footerText}</Button>
                </div>
              </Card.Footer>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default PageLearnAboutServices;
